import SiteHeader from "../components/siteHeader";
import Footer from "../components/footer";

export default function AboutUs({ siteLang, setSiteLang }) {
  let hotelItemsList = [
    {
      name: { en: "Amenities", ar: "وسائل الراحة" },
      items: {
        ar: [
          "واى فاى مجانى",
          "لوبي كبير",
          "مقهى",
          "صالة رياضية ومركز لياقة بدنية",
          "غرف الساونا والبخار",
          "مطعم كبير في الطابق العلوي",
          "موقف سيارات مجاني آمن تحت الأرض",
          "كهرباء متواصلة على مدار الساعة وطوال أيام الأسبوع",
        ],

        en: [
          "Free Wi-Fi",
          "Large lobby",
          "Coffee shop",
          "Gym and fitness center",
          "Sauna and steam rooms",
          "Big restaurant at the top floor",
          "Free secure underground parking",
          "24/7 uninterrupted electricity",
        ],
      },
      myImg: "https://api.parwarhotel.com/images/main/02.png",
    },
    {
      name: {
        en: "Meetings and Events",
        ar: "الاجتماعات والمناسبات",
      },
      items: {
        ar: [
          "قاعات المؤتمرات والاجتماعات",
          "السعات من 12 إلى 130 ضيفًا",
          "مجهزة بالكامل بأدوات الصوت / الفيديو",
        ],
        en: [
          "Conference and meeting rooms",
          "Capacities from 12 to 130 guests",
          "Fully equipped with audio/video tools",
        ],
      },
      myImg: "https://api.parwarhotel.com/images/main/03.png",
    },
    {
      name: { en: "Room Amenities", ar: "وسائل الراحة في الغرفة" },
      items: {
        ar: [
          "أسرة للرضع",
          "تخزين الأمتعة",
          "قهوة وشاي مجانًا",
          "مستلزمات مجاني ة",
        ],
        en: [
          "Cots for babies",
          "Luggage Storage",
          "Complimentary coffee and tea",
          "Complimentary toiletries",
        ],
      },
      myImg: "https://api.parwarhotel.com/images/main/04.png",
    },
    {
      name: {
        en: "Luxurious Design",
        ar: "تصميم فاخر",
      },
      items: {
        ar: [
          "فاخرة وجذابة من الداخل والخارج",
          "ديكور مصمم داخلي",
          "أثاث عالي الجودة تم اختياره خصيصًا",
        ],
        en: [
          "Luxurious and attractive inside and out",
          "Interior designer décor",
          "High-quality specially selected furniture",
        ],
      },
      myImg: "https://api.parwarhotel.com/images/main/05.png",
    },
    {
      name: {
        en: "Services",
        ar: "خدمات",
      },
      items: {
        en: ["24/7 front-desk service", "Room service", "Dry cleaning"],
        ar: [
          "خدمة مكتب الاستقبال على مدار الساعة طوال أيام الأسبوع",
          "خدمة الغرف",
          "التنظيف الجاف",
        ],
      },
      myImg: "https://api.parwarhotel.com/images/main/06.png",
    },
    {
      name: {
        en: "Service Staff",
        ar: "طاقم الخدمة",
      },
      items: {
        ar: [
          "جميع موظفينا مؤهلين تأهيلاً عالياً ومتخصصين ومدربين تدريباً جيداً لتقديم أفضل خدمة",
          "يتقن العديد من الموظفين مجموعة متنوعة من اللغات بما في ذلك الكردية والعربية والإنجليزية والفارسية ، إلخ.",
        ],
        en: [
          "Our staff are all highly qualified, specialized, and well-trained to provide the best service",
          "Many staff are fluent in a variety of languages including Kurdish, Arabic, English, Farsi, etc.",
        ],
      },

      myImg: "https://api.parwarhotel.com/images/main/07.png",
    },
    {
      name: {
        en: "Health and Cleanliness",
        ar: "الصحة والنظافة",
      },
      items: {
        ar: [
          "خدمة الغرف اليومية",
          "التطهير والتنظيف المنتظمين",
          "التطهير والتنظيف المنتظمين",
        ],
        en: [
          "Daily room service",
          "Regular disinfection and cleaning",
          "Regular disinfection and cleaning",
        ],
      },
      myImg: "https://api.parwarhotel.com/images/main/08.png",
    },
    {
      name: {
        en: "Safety",
        ar: "أمن",
      },
      items: {
        ar: [
          "بنيت لإطلاق قانون السلامة مع طرق النار",
          "حراس أمن 24/7 والمراقبة بالفيديو لجميع الأماكن العامة ومرآب السيارات",
        ],
        en: [
          "Built to fire safety code with fire routes",
          "24/7 security guards and video surveillance for all common areas and parking garage",
        ],
      },
      myImg: "https://api.parwarhotel.com/images/main/09.png",
    },
  ];

  let aboutusinfo = {
    hotelname: {
      ar: "په‌روه‌ر هۆتێل",
      en: "THE PARWAR HOTEL",
    },

    hoteldesc: {
      ar: `فندق پەروەر السياحي بدا نشاطه السياحي من عام 2012 بمستوى أربعة نجوم هو ملاذ جميل وفاخر لتجعلك تشعر وكأنك في المنزل سواء في العمل او الاجازة وفي عام 2021 اكملنا عمليات إعادة البناء والتحديث لتحقيق مستوى اعلى من الفخامة والراحة لتجربة لا تنسى`,
      en: `Established in 2012, the 4-star Parwar Hotel is a beautiful and
    luxurious getaway to make you feel at home whether on business or
    vacation. In 2021, we have completed major reconstruction and
    remodeling to bring an even higher level of luxury and comfort for
    an unforgettable experience.`,
    },
  };

  let isArabic = siteLang === "ar" ? true : false;

  return (
    <Main
      siteLang={siteLang}
      setSiteLang={setSiteLang}
      aboutusinfo={aboutusinfo}
      isArabic={isArabic}
      hotelItemsList={hotelItemsList}
    />
  );
}

function Main({
  siteLang,
  setSiteLang,
  aboutusinfo,
  isArabic,
  hotelItemsList,
}) {
  return (
    <div className="aboutus-section">
      <div className="default-section-header">
        <SiteHeader
          siteLang={siteLang}
          setSiteLang={setSiteLang}
          selected={"aboutus"}
        />
      </div>
      <div className="aboutus-head">
        <div className="aboutus-head-imag-container">
          <img src={"https://api.parwarhotel.com/images/main/01.png"} />
        </div>
        <div className="aboutus-head-description-container">
          <div
            style={{ direction: isArabic ? "rtl" : "ltr" }}
            className="aboutus-head-description"
          >
            <h2> {aboutusinfo.hotelname[siteLang]}</h2>
            <p className="responsive-text">{aboutusinfo.hoteldesc[siteLang]}</p>
          </div>
        </div>
      </div>

      <div>
        {hotelItemsList.map((e, i) => {
          return i % 2 == 0 ? (
            <ImageOnRight
              key={"imgCont" + i}
              listItems={e}
              siteLang={siteLang}
              isArabic={isArabic}
            />
          ) : (
            <ImageOnLeft
              key={"imgCont" + i}
              listItems={e}
              siteLang={siteLang}
              isArabic={isArabic}
            />
          );
        })}
      </div>
      <div className="footer-outer-wrapper">
        <Footer isWhite={true} />
      </div>
    </div>
  );
}

function ImageOnRight({ listItems, siteLang, isArabic }) {
  return (
    <div className="aboutsus-right-container">
      <div
        className="aboutsus-right-text-container"
        style={{ direction: isArabic ? "rtl" : "ltr" }}
      >
        <h3 className="aboutsus-text-header">{listItems.name[siteLang]}</h3>
        <ul className="aboutsus-list-container">
          {listItems.items[siteLang].map((e, i) => {
            return (
              <li className="aboutsus-list-item" key={listItems.name + i}>
                {e}
              </li>
            );
          })}
        </ul>
      </div>
      <div className="aboutsus-right-image-container">
        <img className="aboutsus-image" src={listItems.myImg} alt="" />
      </div>
    </div>
  );
}

function ImageOnLeft({ listItems, siteLang, isArabic }) {
  return (
    <div className="aboutsus-left-container">
      <div className="aboutsus-left-image-container">
        <img className="aboutsus-image" src={listItems.myImg} alt="" />
      </div>
      <div
        className="aboutsus-text-container"
        style={{
          direction: isArabic ? "rtl" : "ltr",
        }}
      >
        <h3 className="aboutsus-text-header">{listItems.name[siteLang]}</h3>
        <ul className="aboutsus-list-container">
          {listItems.items[siteLang].map((e, i) => {
            return (
              <li className="aboutsus-list-item" key={"amnetyItem" + i}>
                {e}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
