import "./App.css";
import Home from "./page/Home";

import { Route, BrowserRouter, Routes } from "react-router-dom";
import Rooms from "./page/Rooms";
import AboutUs from "./page/AboutUs";
import Gallery from "./page/Gallery";
import { useState } from "react";
import Book from "./page/Book";

function App() {
  let [siteLang, setSiteLang] = useState("ar");
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route
            path="/"
            element={<Home siteLang={siteLang} setSiteLang={setSiteLang} />}
          />
          <Route
            path="/rooms"
            element={<Rooms siteLang={siteLang} setSiteLang={setSiteLang} />}
          />
          <Route
            path="/aboutus"
            element={<AboutUs siteLang={siteLang} setSiteLang={setSiteLang} />}
          />
          <Route
            path="/gallery"
            element={<Gallery siteLang={siteLang} setSiteLang={setSiteLang} />}
          />
          <Route
            path="/book"
            element={<Book siteLang={siteLang} setSiteLang={setSiteLang} />}
          />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
