import { Link } from "react-router-dom";
import SiteHeader from "../components/siteHeader";

import pool from "../images/ribbonImages/Pool.png";
import food from "../images/ribbonImages/Food.png";
import gym from "../images/ribbonImages/Gym.png";
import meeting from "../images/ribbonImages/Metting.png";

import safety from "../images/ribbonImages/Safety.png";
import luxurious from "../images/ribbonImages/Luxurious-Design-.png";
import services from "../images/ribbonImages/Services.png";
import roomAmneties from "../images/ribbonImages/Room-Amenities-.png";

import Footer from "../components/footer";
import { useEffect, useRef, useState } from "react";

let siteLangData = {
  wlcm: {
    p1: { en: "WELCOME TO ", ar: " مرحبا بكم في" },
    p2: { en: "PARWAR HOTEL", ar: " په‌روه‌ر هۆتێل" },
  },
  book: {
    en: "BOOK NOW",
    ar: "احجز الآن",
  },

  aboutUs: {
    p1: { en: "About ", ar: "" },
    p2: { en: "US ", ar: " عنا" },
  },
  aboutUsText: {
    ar: "تعتبر مدينة دهوك جوهرة مخفية تقع في قلب الاقليم الكردي للعراق وهي وجهة سياحية وتجارية مذهلة مع اثارها الجميلة. بحيرة جميلة وحدائق وجبال ذات مناظر خلابة. فندق پەروەر السياحي بداية افتتاحه عام 2012 أكمل مؤخرا عمليات اعادة البناء الرئيسية وبنماذج راقية لجعل زيارة الضيوف تجربة لا تنسى",
    en: `  The city of Dohuk, a hidden gem situated in the heart of the Kurdish
  Region of Iraq, is an amazing tourist and business destination with
  its ancient monuments, beautiful lake and parks, and scenic
  mountains. The Parwar Hotel, first established in 2012, has recently
  completed major reconstruction and modeling upgrades to make your
  visit an unforgettable experience.`,
  },

  aboutUSButton: {
    ar: "أكثر",
    en: "More",
  },

  suitHead: {
    p1: { en: "DELUXE ", ar: " جناح " },
    p2: { en: "SUITE", ar: "ديلوكس" },
  },

  suitText: {
    en: "The city of Dohuk, a hidden gem situated in the heart of the Kurdish Region of Iraq, is an amazing tourist and business destination with its ancient monuments, beautiful lake and parks, and scenic mountains. The Parwar Hotel, first established in 2012, has recently completed major reconstruction and modeling upgrades to make your visit an unforgettable experience.    ",
    ar: `ستكون لك فرصة الاطلاع على ديكور رائع في جناح فخم و واسع مناسب لكبار الشخصيات مفروش بأفضل المفروشات مع سرير ملكي كبير , اجود البياضات , جاكوزي خاص , مكتب عمل مع صالة لاستراحة الضيوف `,
  },

  suitBut: {
    ar: "غرف",
    en: "ROOMS",
  },

  restHead: {
    p1: {
      en: "RESTURANT",
      ar: "مطعم",
    },
    p2: { en: "", ar: "" },
  },
  restData: {
    en: `Make your experience trip experience unforgettable at our Parwar Restaurant. Take in a beautiful 270-degree panoramic view of Duhok at the top floor while enjoying our delicious local specialty and chefinspired meals at affordable prices.`,
    ar: "اجعل تجربتك في سفرة لا تنسى في مطعم پەروەر واستمتع بإطلالة بانوراميه جميلة بزاوية 270 درجة على مدينة دهو ك في الطابق العلوي وفي نفس الوقت تستمتع بتخصصنا المحلي اللذيذ والوجبات المستوحاة من الطهاة الماهرين وبأسعار معقولة",
  },

  gymHead: {
    p1: { en: "GYM & ", ar: "صالة رياضية و " },
    p2: { en: "WORKOUT", ar: "مركز جيم" },
  },
  gymData: {
    en: `Find health and relaxation in our spacious and fully equipped
    air-conditioned gyms. Equipped with the latest state-of-the-art
    exercise equipment, large TV screens, relaxing sauna and steam room.`,
    ar: `اكتشف الصحة والاسترخاء في صالاتنا الرياضية الواسعة المكيفة والمجهزة تجهيزا كاملا ومجهزة بأحدث اجهزة التمارين الرياضية وشاشة تلفزيون كبيرة وساونا للاسترخاء وغرفة بخار`,
  },

  poolHead: {
    p1: { en: "POOL AND ", ar: "حوض السباحة و " },
    p2: { en: "SAUNA", ar: "ساونا" },
  },
  poolData: {
    en: "Experience pure bliss at our hotel's pool and sauna. Our inviting pool beckons with its crystal-clear waters, perfect for a refreshing dip. Adjacent to the pool, the sauna offers a haven of relaxation, where soothing heat melts your cares away. Indulge in the perfect pairing of aquatic serenity and tranquil warmth for an unforgettable escape. Your oasis of relaxation awaits.",
    ar: "جرب النعيم النقي في حمام السباحة والساونا بالفندق. حمام السباحة الجذاب الخاص بنا يغري بمياهه الصافية ، وهو مثالي للسباحة المنعشة. بجوار المسبح ، توفر الساونا ملاذًا للاسترخاء ، حيث تعمل الحرارة المهدئة على إذابة اهتماماتك بعيدًا. انغمس في الاقتران المثالي بين الصفاء المائي والدفء الهادئ من أجل ملاذ لا يُنسى. واحة الاسترخاء الخاصة بك في انتظارك.",
  },

  meetingHead: {
    p1: { en: "MEETING ", ar: "قاعة " },
    p2: { en: "ROOMS", ar: "الاجتماعات" },
  },
  meetingData: {
    en: "Perfect for any occasion, whether for small business, large conferences, or VIP occasions. Choose to book from any one of our spacious rooms to suit your needs. All rooms come fully-equipped with visual and audio systems. Ask about our catering service.",
    ar: "صالاتنا مثالية لاي مناسبة سواء للأعمال الصغيرة او المؤتمرات الكبيرة او المناسبات لكبار الشخصيات اختر الحجز لأية صالة لتناسب احتياجاتك . علما ان جميع الصالات مجهزة تجهيزا  كاملا بأنظمة بصرية  وصوتية واسال عن خدمات تقديم الطعام لدينا .",
    list: {
      en: [
        "Exquisite and luxurious VIP hall for 16 people ( $400/day)",
        "Businessmen meeting room hosting 12 people ( $100/day)",
        "Large conference hall hosting 130 people ( $250/day)",
        "Conference hall hosting 60 people ( $200/day)",
      ],
      ar: [
        "قاعة راقية وفاخرة لكبار الشخصيات والمناصب العالية تتسع ل 16 فرد باجر قدره ",
        " قاعة رجال الاعمال تستضيف 12 فردا باجر ",
        "قاعة كبيرة للاجتماعات تستضيف 130 فردا باجر ",
        "قاعة المؤتمرات تستضيف 60 فردا باجر $200 لليوم الواحد.",
      ],
    },
  },
};

export default function Home({ siteLang, setSiteLang }) {
  let isArabic = siteLang === "ar" ? true : false;
  return (
    <div className="home-container">
      <HeroSection
        siteLang={siteLang}
        setSiteLang={setSiteLang}
        isArabic={isArabic}
      />
      <TopSection
        siteLang={siteLang}
        setSiteLang={setSiteLang}
        isArabic={isArabic}
      />
      <ThirdSection siteLang={siteLang} isArabic={isArabic} />
      <ForthSection siteLang={siteLang} isArabic={isArabic} />
      <div className="footer-outer-wrapper">
        <Footer />
      </div>
    </div>
  );
}

function HeroSection({ siteLang, setSiteLang, isArabic }) {
  return (
    <div className="hero-section">
      <div className="default-section-header__main">
        <SiteHeader
          siteLang={siteLang}
          setSiteLang={setSiteLang}
          selected={"home"}
        />
      </div>
      <div className="hero-section-main">
        <img
          className="hero-section-main__image"
          src={"https://api.parwarhotel.com/images/main/hotelMain.png"}
        />
        <div className="hero-section-main__welcome">
          <div
            className="welcome-cotainer"
            style={{ direction: isArabic ? "rtl" : "ltr" }}
          >
            <h2 className="welcome-cotainer-text">
              <span className="text-green">
                {siteLangData.wlcm.p1[siteLang]}
                <br />
              </span>
              {siteLangData.wlcm.p2[siteLang]}
            </h2>
            <Link className="link-button-green" to="/book">
              {siteLangData.book[siteLang]}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

function TopSection({ siteLang, setSiteLang, isArabic }) {
  return (
    <div className="top-section">
      <Ribbon />

      <div className="top-section-head">
        <div className="top-section-img-container">
          <img
            className="top-section-img"
            src={"https://api.parwarhotel.com/images/main/first.png"}
            alt="first"
          />
        </div>

        <div
          className="top-section-info-wrapper"
          style={{ direction: isArabic ? "rtl" : "ltr" }}
        >
          <div className="top-section-info-container">
            <h2 className="top-section-info__name">
              {siteLangData.aboutUs.p1[siteLang]}
              <span className="text-amber">
                {siteLangData.aboutUs.p2[siteLang]}
              </span>
            </h2>
            <p className="top-section-info__text responsive-text">
              {siteLangData.aboutUsText[siteLang]}
            </p>
            <Link className="link-button-green" to="/aboutus">
              {siteLangData.aboutUSButton[siteLang]}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

function SecondSection({ siteLang, isArabic }) {
  return (
    <div className="second-section">
      <div className="second-section-image-container">
        <img src={""} alt="second" className="second-section-image-one" />

        <img src={""} alt="second" className="second-section-image-two" />
      </div>
      <div className="second-section-text-container">
        <h2 className="second-section-header">
          {siteLangData.gym.p1[siteLang]}{" "}
          <span className="text-green">{siteLangData.gym.p2[siteLang]}</span>
        </h2>
        <p
          style={{ direction: isArabic ? "rtl" : "ltr" }}
          className="second-section-text responsive-text"
        >
          {siteLangData.gymData[siteLang]}
        </p>
        <Link className="link-button-green" to="/gallery">
          {siteLangData.gymDataButton[siteLang]}
        </Link>
      </div>
    </div>
  );
}

function ThirdSection({ siteLang, isArabic }) {
  return (
    <div className="third-section">
      <div className="third-section-head">
        <div className="third-section-text-container">
          <div
            className="third-section-text-wrapper"
            style={{ direction: isArabic ? "rtl" : "ltr" }}
          >
            <h2 className="third-section-text-header">
              {siteLangData.suitHead.p1[siteLang]}
              <span className="text-amber">
                {siteLangData.suitHead.p2[siteLang]}
              </span>
            </h2>
            <p
              style={{ direction: isArabic ? "rtl" : "ltr" }}
              className="third-section-text responsive-text"
            >
              {siteLangData.suitText[siteLang]}
            </p>
            <Link className="link-button-amber" to="/rooms">
              {siteLangData.suitBut[siteLang]}
            </Link>
          </div>
        </div>

        <div className="third-section-image-container">
          <img
            src={"https://api.parwarhotel.com/images/main/before-last.png"}
            className="third-section-image"
            alt="third_sec_img"
          />
        </div>
      </div>
      <Ribbon_two />
    </div>
  );
}

function ForthSection({ siteLang, isArabic }) {
  return (
    <div className="forth-section">
      <ForthSectionLeftOverFlow
        siteLang={siteLang}
        isArabic={isArabic}
        dataHead={siteLangData.restHead}
        dataText={siteLangData.restData}
        sectImg={"https://api.parwarhotel.com/images/main/last.png"}
      />
      <ForthSectionRightOverFlow
        siteLang={siteLang}
        isArabic={isArabic}
        dataHead={siteLangData.gymHead}
        dataText={siteLangData.gymData}
        sectImg={"https://api.parwarhotel.com/images/main/gym.png"}
      />
      <ForthSectionLeftOverFlow
        siteLang={siteLang}
        isArabic={isArabic}
        dataHead={siteLangData.poolHead}
        dataText={siteLangData.poolData}
        sectImg={"https://api.parwarhotel.com/images/main/pool.png"}
      />
      <ForthSectionRightOverFlow
        siteLang={siteLang}
        isArabic={isArabic}
        dataHead={siteLangData.meetingHead}
        dataText={siteLangData.meetingData}
        sectImg={"https://api.parwarhotel.com/images/main/meeting.png"}
      />
    </div>
  );
}

function ForthSectionLeftOverFlow({
  siteLang,
  isArabic,
  dataHead,
  dataText,
  sectImg,
}) {
  let [height, setHeight] = useState(0);
  let ElementHeight = useRef(null);

  useEffect(() => {
    setHeight(ElementHeight.current.clientHeight);
  }, [ElementHeight]);

  function calcHright(thisHeight) {
    return (thisHeight * 100) / 1920 + (400 * 100) / 1920;
  }
  function calcMaxHeight(thisHeight) {
    return thisHeight + 400;
  }

  return (
    <div className="forth-section-item">
      <div
        className="forth-section-image-container"
        style={{
          height: calcHright(height) + "vw",
          maxHeight: calcMaxHeight(height) + "px",
        }}
      >
        {
          <img
            src={sectImg}
            className="forth-section-image"
            alt="forth-section-img"
          />
        }
      </div>
      <div className="forth-section-text-wrapper">
        <div
          ref={ElementHeight}
          className="forth-section-text-container__left_overflow"
          style={{ direction: isArabic ? "rtl" : "ltr" }}
        >
          <h2 className="forth-section-header">
            {dataHead.p1[siteLang]}
            <span className="text-green">{dataHead.p2[siteLang]}</span>
          </h2>
          <p className="forth-section-text responsive-text-small">
            {dataText[siteLang]}
          </p>
          {dataText.list ? dataList() : null}
        </div>
      </div>
    </div>
  );
}
function ForthSectionRightOverFlow({
  siteLang,
  isArabic,
  dataHead,
  dataText,
  sectImg,
}) {
  let [height, setHeight] = useState(0);
  let ElementHeight = useRef(null);

  useEffect(() => {
    setHeight(ElementHeight.current.clientHeight);
  }, [ElementHeight]);

  function calcHright(thisHeight) {
    return (thisHeight * 100) / 1920 + (400 * 100) / 1920;
  }
  function calcMaxHeight(thisHeight) {
    return thisHeight + 400;
  }

  return (
    <div className="forth-section-item">
      <div className="forth-section-text-wrapper">
        <div
          ref={ElementHeight}
          className="forth-section-text-container__right_overflow"
          style={{ direction: isArabic ? "rtl" : "ltr" }}
        >
          <h2 className="forth-section-header">
            {dataHead.p1[siteLang]}
            <span className="text-green">{dataHead.p2[siteLang]}</span>
          </h2>
          <p className="forth-section-text responsive-text-small">
            {dataText[siteLang]}
          </p>
          {dataText.list ? dataList(dataText.list[siteLang]) : null}
        </div>
      </div>
      <div
        className="forth-section-image-container"
        style={{
          height: calcHright(height) + "vw",
          maxHeight: calcMaxHeight(height) + "px",
        }}
      >
        {
          <img
            src={sectImg}
            className="forth-section-image"
            alt="forth-section-img"
          />
        }
      </div>
    </div>
  );
}

function dataList(list) {
  return (
    <ul className="forth-section-list responsive-text-small">
      {list.map((item, i) => (
        <li key={"listItem" + i} className="forth-section-list-item">
          {item}
        </li>
      ))}
    </ul>
  );
}

function Ribbon() {
  return (
    <div className="ribbon-section">
      <div className="ribbon-image-conatainer">
        <img src={food} />
        <p>RERSTURANT</p>
      </div>
      <div className="ribbon-image-conatainer">
        <img src={gym} />
        <p>GYM AND WORKOUT</p>
      </div>
      <div className="ribbon-image-conatainer">
        <img src={pool} />
        <p>POOL AND SUNA</p>
      </div>
      <div className="ribbon-image-conatainer">
        <img src={meeting} />
        <p>MEETING ROOMS</p>
      </div>
    </div>
  );
}

function Ribbon_two() {
  return (
    <div className="ribbon-section">
      <div className="ribbon-image-conatainer">
        <img src={safety} />
        <p>SAFETY</p>
      </div>
      <div className="ribbon-image-conatainer">
        <img src={luxurious} />
        <p>LUXURIOUS DESIGN</p>
      </div>

      <div className="ribbon-image-conatainer">
        <img src={services} />
        <p>SERVICES</p>
      </div>

      <div className="ribbon-image-conatainer">
        <img src={roomAmneties} />
        <p>AMENITIES</p>
      </div>
    </div>
  );
}
