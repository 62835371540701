import { getRooms } from "../services/fetchApi";
import { useEffect, useState } from "react";
import Room from "../components/Room";
import SiteHeader from "../components/siteHeader";
import Footer from "../components/footer";

export default function Rooms({ siteLang, setSiteLang }) {
  let isArabic = siteLang == "ar" ? true : false;

  let roomLangData = {
    roomType: {
      en: "ROOM TYPES",
      ar: "أنواع الغرف",
    },
    note: {
      en: "NOTE",
      ar: "ملحوظة",
    },
    noteOne: {
      en: "ALL ROOMS HAVE A 12% DISCOUNT FOR COMPANIES AND PRIVATE OR GOVERNMENT ORGANIZATIONS.",
      ar: "جميع الغرف بها خصم 12٪ للشركات والمؤسسات الخاصة أو الحكومية.",
    },
  };

  let [rooms, setRooms] = useState([]);

  useEffect(() => {
    async function getData() {
      let data = await getRooms();
      await setRooms(data);
    }
    getData();
  }, []);

  return (
    <div className="rooms-section">
      <div className="default-section-header">
        <SiteHeader
          siteLang={siteLang}
          setSiteLang={setSiteLang}
          selected={"rooms"}
        />
      </div>

      <div className="rooms-main-wrapper">
        <div
          className="booking-main-header-wrapper"
          style={{ direction: isArabic ? "rtl" : "ltr" }}
        >
          <h2>{roomLangData.roomType[siteLang]}</h2>
        </div>

        <div className="rooms-wrapper">
          {rooms.map((room) => {
            return (
              <Room
                key={room.room}
                room={room}
                siteLang={siteLang}
                isArabic={isArabic}
              />
            );
          })}
        </div>

        <div className="booking-notification-section">
          <div className="booking-notification-section__imp-wrapper">
            <p>{roomLangData.note[siteLang]}</p>
          </div>
          <ul className="booking-notification-section__list">
            <li className="booking-notification-section__list-item">
              {roomLangData.noteOne[siteLang]}
            </li>
          </ul>
        </div>
      </div>

      <div style={{ background: "white" }}>
        <div className="footer-outer-wrapper">
          <Footer isWhite={true} />
        </div>
      </div>
    </div>
  );
}
