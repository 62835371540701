import insta from "../images/insta.svg";
import facebook from "../images/facebook.svg";
import twitter from "../images/twitter.svg";

export default function Footer({ isWhite = false }) {
  return (
    <div
      className="footer-wrapper"
      style={{ background: isWhite ? "white" : "" }}
    >
      <div className="footer__left">
        <p className="responsive-text">parwar.hotel@yahoo.com</p>
        <p className="responsive-text">+964 750 878-2424</p>
        <p className="responsive-text">+964 770 033-2424</p>
      </div>
      <div className="footer__mid ">
        <p className="responsive-text">© 2023 By Muba Tech</p>
      </div>
      <div className="footer__right">
        <a href="https://www.instagram.com/parwar.hotel/" target="_blank">
          <img src={insta} />
        </a>
        <a href="https://www.facebook.com/parwarhotel" target="_blank">
          <img src={facebook} />
        </a>
      </div>
    </div>
  );
}
