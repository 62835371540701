import { useState, useRef } from "react";
import SiteHeader from "../components/siteHeader";
import emailjs from "@emailjs/browser";
import Footer from "../components/footer";
import sendImg from "../images/send.svg";

export default function Book({ siteLang, setSiteLang }) {
  const form = useRef();

  let isArabic = siteLang === "ar" ? true : false;

  let bookingData = {
    bookingHeader: {
      ar: "حجز الغرف",
      en: "BOOKING ROOM/s",
    },
    name: {
      en: "Name",
      ar: "اسم",
    },
    email: {
      en: "Email",
      ar: "بريد إلكتروني",
    },
    phone: {
      en: "Phone",
      ar: "رقم الهاتف",
    },
    roomType: {
      en: "Room Type",
      ar: "نوع الغرفة",
    },
    roomNum: {
      en: "Number of Rooms",
      ar: "عدد الغرف",
    },
    adultsNum: {
      en: "Adults (Number)",
      ar: "الكبار (عدد)",
    },
    childrenNum: {
      en: "Children (Number)",
      ar: "الأطفال (عدد)",
    },
    date: {
      en: "Date",
      ar: "تاريخ",
    },
    dateFrom: {
      en: "From",
      ar: "من",
    },
    dateTo: {
      en: "To",
      ar: "إلى",
    },
    yourMessage: {
      en: "Your Message",
      ar: "رسالتك",
    },
    send: {
      p1: { en: "SEND", ar: "ارسل" },
      p2: { en: "REQUEST", ar: "طلب" },
    },
    impNote: {
      en: "IMPORTANT NOTE",
      ar: "ملاحظة مهمة",
    },
    noteOne: {
      en: "- IF YOU WISH TO RESERVE MORE THAN ONE ROOM TYPE, PLEASE SEND A SEPARATE REQUEST FOR EACH ROOM",
      ar: "- إذا كنت ترغب في حجز أكثر من نوع غرفة واحدة ، فيرجى إرسال طلب منفصل لكل غرفة",
    },
    noteTwo: {
      en: "- YOU WILL RECEIVE AN EMAIL CONFIRMATION WITH ADDITIONAL SHORTLY AFTER YOUR REQUEST.",
      ar: "- سوف تتلقى تأكيدًا عبر البريد الإلكتروني بعد وقت قصير من طلبك.",
    },
  };

  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_7b1k6lr",
        "template_ze9dqs5",
        form.current,
        "XztLqWRRNgAxcMsph"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  }
  const today = new Date().toISOString().substr(0, 10);
  return (
    <div className="booking-section">
      <div className="default-section-header">
        <SiteHeader
          siteLang={siteLang}
          setSiteLang={setSiteLang}
          selected={"book"}
        />
      </div>

      <div className="booking-main">
        <div
          className="booking-main-header-wrapper"
          style={{ direction: isArabic ? "rtl" : "ltr" }}
        >
          <h2>{bookingData.bookingHeader[siteLang]}</h2>
        </div>

        <form
          className="email-booking-form"
          ref={form}
          onSubmit={sendEmail}
          style={{ direction: isArabic ? "rtl" : "ltr" }}
        >
          <div className="email-booking__upper">
            <input
              className="email-booking__upper-input"
              type="text"
              placeholder={bookingData.name[siteLang]}
              name="from_name"
              required
            />

            <input
              className="email-booking__upper-input"
              type="email"
              placeholder={bookingData.email[siteLang]}
              name="from_email"
              required
            />

            <input
              className="email-booking__upper-input"
              type="text"
              placeholder={bookingData.phone[siteLang]}
              name="phone"
              required
            />

            <div className="email-booking__upper-input upper-data-container">
              <p>{bookingData.date[siteLang]}</p>
              <div className="booking__upper-input__date-container">
                <p>{bookingData.dateFrom[siteLang]} : </p>

                <input
                  type="date"
                  name="date-from"
                  required
                  defaultValue={today}
                  min={today}
                />
              </div>
              <div className="booking__upper-input__date-container">
                <p>{bookingData.dateTo[siteLang]} : </p>

                <input
                  type="date"
                  placeholder={bookingData.date[siteLang]}
                  name="date-to"
                  defaultValue={today}
                  min={today}
                  required
                />
              </div>
            </div>
            {
              <select
                className="email-booking__upper-input"
                name="room_type"
                required
              >
                <option selected={true} disabled="disabled">
                  {bookingData.roomType[siteLang]}
                </option>
                <option value="singe_room">
                  {isArabic ? "غرفة فردية قياسية" : "Single Room Standard"}
                </option>
                <option value="double_room">
                  {isArabic ? "غرفة زوجية قياسية" : "Double Room Standard"}
                </option>
                <option value="triple_room">
                  {isArabic ? "غرفة ثلاثية قياسية" : "Triple Room Standard"}
                </option>
                <option value="standard_suite">
                  {isArabic ? "سويت قياسي" : "Standard Suite"}
                </option>
                <option value="vip_suite">
                  {isArabic ? "جناح VIP" : "The VIP Suite"}
                </option>
                <option value="private_suite">
                  {isArabic ? "جناح خاص" : "Private Suite"}
                </option>
                <option value="special_deluxe">
                  {isArabic ? "جناح ديلوكس خاص" : "Special Deluxe Suite"}
                </option>
              </select>
            }

            <input
              className="email-booking__upper-input"
              type="number"
              placeholder={bookingData.roomNum[siteLang]}
              name="rooms_number"
              required
            />

            <input
              className="email-booking__upper-input"
              type="number"
              placeholder={bookingData.adultsNum[siteLang]}
              name="adults"
              required
            />
            <input
              className="email-booking__upper-input"
              type="number"
              placeholder={bookingData.childrenNum[siteLang]}
              name="children"
              required
            />
          </div>

          <div className="email-booking__lower">
            <textarea
              className="email-booking__lower-textarea"
              name="message"
              placeholder={bookingData.yourMessage[siteLang]}
            />
            <button className="email-booking__lower-button">
              <p>
                {bookingData.send.p1[siteLang]}
                <br />
                {bookingData.send.p2[siteLang]}
              </p>
              <img src={sendImg} style={{ rotate: isArabic ? "180deg" : "" }} />
            </button>
          </div>
        </form>

        <div
          className="booking-notification-section"
          style={{ direction: isArabic ? "rtl" : "ltr" }}
        >
          <div className="booking-notification-section__imp-wrapper">
            <p>{bookingData.impNote[siteLang]}</p>
          </div>
          <ul className="booking-notification-section__list">
            <li className="booking-notification-section__list-item">
              {bookingData.noteOne[siteLang]}
            </li>
            <li className="booking-notification-section__list-item">
              {bookingData.noteTwo[siteLang]}
            </li>
          </ul>
        </div>

        <hr />
        <Footer />
      </div>
    </div>
  );
}
