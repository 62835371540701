import { Link } from "react-router-dom";
import { useState } from "react";

import ToolTip from "./toolTpiLogic";
import hamburgerSVG from "../images/hamburger.svg";

let headerData = {
  home: { en: "Home", ar: "الصفحة الرئيسية" },
  AboutUs: { en: "About Us", ar: "عنا" },
  rooms: { en: "Rooms", ar: "غرف" },
  gallery: { en: "Gallery", ar: "الألبوم" },
  booknow: { en: "Book Now", ar: "احجز الآن" },
  changeLang: { en: "Change language", ar: "تغيير اللغة" },
};

export default function SiteHeader({ siteLang, setSiteLang, selected }) {
  let isArabic = siteLang == "ar" ? true : false;
  return (
    <div className="site-header">
      <div className="site-header-links-container">
        <div className="site-header-links-wrapper">
          <Link
            style={{ color: selected === "home" ? "rgb(186, 145, 97)" : "" }}
            className="site-header-link"
            to="/"
          >
            {headerData.home[siteLang]}
          </Link>
          <Link
            style={{ color: selected === "aboutus" ? "rgb(186, 145, 97)" : "" }}
            className="site-header-link"
            to="/aboutus"
          >
            {headerData.AboutUs[siteLang]}
          </Link>
          <Link
            style={{ color: selected === "rooms" ? "rgb(186, 145, 97)" : "" }}
            className="site-header-link"
            to="/rooms"
          >
            {headerData.rooms[siteLang]}
          </Link>
          <Link
            style={{ color: selected === "gallery" ? "rgb(186, 145, 97)" : "" }}
            className="site-header-link"
            to="/gallery"
          >
            {headerData.gallery[siteLang]}
          </Link>
          <Link
            style={{ color: selected === "book" ? "rgb(186, 145, 97)" : "" }}
            className="site-header-link"
            to="/book"
          >
            {headerData.booknow[siteLang]}
          </Link>
        </div>

        <LanguageButton siteLang={siteLang} setSiteLang={setSiteLang} />
        <HeaderToolTip
          headerData={headerData}
          siteLang={siteLang}
          setSiteLang={setSiteLang}
          isArabic={isArabic}
        />
      </div>
    </div>
  );
}

function HeaderToolTip({ siteLang, setSiteLang, headerData, isArabic }) {
  let [showMenu, setShowMenu] = useState(false);

  function onclickOutside() {
    setShowMenu(false);
  }

  function handleLangChange() {
    siteLang === "en" ? setSiteLang("ar") : setSiteLang("en");
  }

  return (
    <div className="site-header-tool-tip-button">
      <button
        className="tool-tip-button"
        onClick={() => {
          setShowMenu(true);
        }}
      >
        {<img src={hamburgerSVG} />}
      </button>
      <ToolTip show={showMenu} onclickOutside={onclickOutside}>
        <Link style={{ direction: isArabic ? "rtl" : "ltr" }} to="/">
          {headerData.home[siteLang]}
        </Link>
        <Link style={{ direction: isArabic ? "rtl" : "ltr" }} to="/aboutus">
          {headerData.AboutUs[siteLang]}
        </Link>
        <Link style={{ direction: isArabic ? "rtl" : "ltr" }} to="/rooms">
          {headerData.rooms[siteLang]}
        </Link>
        <Link style={{ direction: isArabic ? "rtl" : "ltr" }} to="/gallery">
          {headerData.gallery[siteLang]}
        </Link>
        <Link style={{ direction: isArabic ? "rtl" : "ltr" }} to="/book">
          {headerData.booknow[siteLang]}
        </Link>

        <LanguageButtonTool
          siteLang={siteLang}
          setSiteLang={setSiteLang}
          isArabic={isArabic}
        />
      </ToolTip>
    </div>
  );
}

function LanguageButton({ siteLang, setSiteLang }) {
  let [showLang, setShowLang] = useState(false);

  function handeClickLang() {
    setShowLang((current) => !current);
  }

  function handleClikcOut() {
    setShowLang(false);
  }

  return (
    <div className="site-header-language-buton-container">
      <button
        className="site-header-language-button"
        onClick={() => {
          handeClickLang();
        }}
      >
        {siteLang === "en" ? "EN" : siteLang === "ar" ? "AR" : null}
      </button>
      <ToolTip show={showLang} onclickOutside={handleClikcOut}>
        {siteLang !== "en" ? (
          <button
            onClick={() => {
              setShowLang(false);
              setSiteLang("en");
            }}
          >
            EN
          </button>
        ) : null}
        {siteLang !== "ar" ? (
          <button
            onClick={() => {
              setShowLang(false);
              setSiteLang("ar");
            }}
          >
            AR
          </button>
        ) : null}
      </ToolTip>
    </div>
  );
}

function LanguageButtonTool({ siteLang, setSiteLang, isArabic }) {
  let [showLang, setShowLang] = useState(false);

  function handleClikcOut() {
    setShowLang(false);
  }

  return (
    <div
      className="site-header-language-buton-container__small"
      style={{ direction: isArabic ? "rtl" : "ltr" }}
    >
      <button
        className="site-header-language-button__small"
        onClick={() => setShowLang(true)}
      >
        {siteLang === "en" ? "EN" : siteLang === "ar" ? "AR" : null}
      </button>
      <ToolTip show={showLang} onclickOutside={handleClikcOut}>
        {siteLang !== "en" ? (
          <button
            onClick={() => {
              setShowLang(false);
              setSiteLang("en");
            }}
          >
            EN
          </button>
        ) : null}
        {siteLang !== "ar" ? (
          <button
            onClick={() => {
              setShowLang(false);
              setSiteLang("ar");
            }}
          >
            AR
          </button>
        ) : null}
      </ToolTip>
    </div>
  );
}
