import { useRef, useEffect } from "react";

export default function ToolTip(props) {
  let ref = useRef(null);
  let { onclickOutside } = props;

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        onclickOutside && onclickOutside();
      }
    }
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [onclickOutside]);

  if (!props.show) return null;

  return (
    <div ref={ref} className="tool-tip">
      {props.children}
    </div>
  );
}
