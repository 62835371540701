import { useState } from "react";
import servicesIcons from "../images/assests/servicesIcons.png";
import { useNavigate } from "react-router-dom";

export default function Room({ room, siteLang, isArabic }) {
  const navigate = useNavigate();

  let myThumbnails = [
    ` https://api.parwarhotel.com/images/thumbnails/${room.id}-1.png`,
    ` https://api.parwarhotel.com/images/thumbnails/${room.id}-2.png`,
    ` https://api.parwarhotel.com/images/thumbnails/${room.id}-3.png`,
    ` https://api.parwarhotel.com/images/thumbnails/${room.id}-4.png`,
  ];

  let myImages = [
    `https://api.parwarhotel.com/images/${room.id}-1.png`,
    `https://api.parwarhotel.com/images/${room.id}-2.png`,
    `https://api.parwarhotel.com/images/${room.id}-3.png`,
    `https://api.parwarhotel.com/images/${room.id}-4.png`,
  ];

  let [image, setImage] = useState(0);
  function changeImage(imgindx) {
    image = setImage(imgindx);
  }

  return (
    <div
      className="room-wrapper"
      // style={{ direction: isArabic ? "rtl" : "ltr" }}
    >
      <div className="room-cotainer">
        <div className="room-thumbnail-images-wrapper">
          {myThumbnails.map((e, i) => {
            return (
              <div
                key={"roomImg" + i}
                className="room-thumbnail-images-container"
              >
                <img
                  loading="lazy"
                  className=""
                  src={e}
                  onClick={() => changeImage(i)}
                  alt="room_image"
                />
              </div>
            );
          })}
        </div>
        <div className="room-info-container-image-container">
          <img loading="lazy" src={myImages[image]} alt="main" />
        </div>
        <div
          className="room-info-container-text-container"
          style={{ direction: isArabic ? "rtl" : "ltr" }}
        >
          <p className="room-info-container-text-name">
            {isArabic ? room.name : room.room}
          </p>
          <p className="room-info-container-text-desc">{room[siteLang]}</p>
          <img className="room-info-container-img" src={servicesIcons} />
          <button
            onClick={() => {
              navigate("/book");
            }}
            className="room-info-container-text-button"
          >
            {isArabic ? "احجز الآن" : "Book Now"}
          </button>
        </div>
      </div>
    </div>
  );
}
