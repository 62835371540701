import { useEffect, useState } from "react";
import SiteHeader from "../components/siteHeader";
import Footer from "../components/footer";

export default function Gallery({ siteLang, setSiteLang }) {
  const list = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24,
  ];
  let [myData, setMyData] = useState();

  let isArabic = siteLang === "ar" ? true : false;

  useEffect(() => {
    async function fetchPhotos() {
      let response = await fetch("https://api.parwarhotel.com/api/v1/num");
      let data = await response.json();

      setMyData(data);
    }

    fetchPhotos();
  }, []);

  function createNumberArrayStartingFromOne(length) {
    const numberArray = [];

    for (let i = 1; i <= length; i++) {
      numberArray.push(i);
    }

    return numberArray;
  }

  let numberArray = createNumberArrayStartingFromOne(myData);

  numberArray = numberArray.reverse();

  let chunkSize = 11;
  let chunks = [];
  let smallerChunk = [];

  for (let i = 0; i < numberArray.length; i += chunkSize) {
    chunks.push(numberArray.slice(i, i + chunkSize));
  }

  for (let i = 0; i < chunks.length; i++) {
    smallerChunk.push([[], [], []]);
    smallerChunk[i][0] = chunks[i].slice(0, 4);
    smallerChunk[i][1] = chunks[i].slice(4, 8);
    smallerChunk[i][2] = chunks[i].slice(8, 11);
  }

  let [imageDisplay, setImageDisplay] = useState("");
  let [imageModal, setImageModal] = useState(false);

  function handleImageClick(id) {
    setImageDisplay(`https://api.parwarhotel.com/images/gallery/${id}.jpg`);
    setImageModal(true);
  }

  return (
    <div className="gallery-section">
      <div className="default-section-header">
        <SiteHeader
          siteLang={siteLang}
          setSiteLang={setSiteLang}
          selected={"gallery"}
        />
      </div>

      <div
        style={{
          margin: "0 min(192px, 9vw)",
          direction: isArabic ? "rtl" : "ltr",
        }}
      >
        <div className="booking-main-header-wrapper">
          <h2>{isArabic ? "البوم صور" : "PHOTO GALLERY"}</h2>
        </div>
      </div>
      {smallerChunk.length > 0 ? (
        <div className="gallary-dynamic-grid-container">
          {smallerChunk.map((e) => {
            return e.map((item, innerIndex) => {
              return innerIndex === 0 && item.length > 0 ? (
                <div
                  key={"outerDiv" + innerIndex}
                  className="gallary-dynamic-container"
                >
                  <div className="gallary-dynamic-grid__one">
                    {item.map((subItem) => {
                      return imageElement(handleImageClick, subItem);
                    })}
                  </div>
                </div>
              ) : innerIndex === 1 && item.length > 0 ? (
                <div
                  className="gallary-dynamic-container"
                  key={"outerDiv" + innerIndex}
                >
                  <div className="gallary-dynamic-grid__two">
                    {item.map((subItem) => {
                      return imageElement(handleImageClick, subItem);
                    })}
                  </div>
                </div>
              ) : innerIndex === 2 && item.length > 0 ? (
                <div
                  className="gallary-dynamic-container"
                  key={"outerDiv" + innerIndex}
                >
                  <div className="gallary-dynamic-grid__three">
                    {item.map((subItem) => {
                      return imageElement(handleImageClick, subItem);
                    })}
                  </div>
                </div>
              ) : null;
            });
          })}
        </div>
      ) : null}
      <div className="footer-outer-wrapper">
        <Footer></Footer>
      </div>
      <Modal
        trigger={imageModal}
        setTrigger={setImageModal}
        imgSrc={imageDisplay}
      />
    </div>
  );
}

function imageElement(handleImageClick, subItem) {
  return (
    <img
      loading="lazy"
      key={"img" + subItem}
      onClick={() => {
        handleImageClick(subItem);
      }}
      srcSet={`https://api.parwarhotel.com/images/gallery/thumbnails/${subItem}.jpg 400w, https://api.parwarhotel.com/images/gallery/${subItem}.jpg 1500w`}
      sizes="(max-width: 500px) 400px,
         1500px"
      src={`https://api.parwarhotel.com/images/gallery/thumbnails/${subItem}.jpg `}
      alt={"GalleryImage" + subItem}
    />
  );
}

function Modal(props) {
  function handleCloseModal(e) {
    if (e.target.id === "modal") props.setTrigger(false);
  }

  return props.trigger ? (
    <div
      className="modal-container"
      id="modal"
      onClick={(e) => handleCloseModal(e)}
    >
      <div className="modal-image-container">
        <img className="modal-image" src={props.imgSrc} alt="img" />
      </div>
    </div>
  ) : null;
}
